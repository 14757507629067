import { useState } from 'react';

const WINNING_LINES = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6]
]

const PLAYER_MARKER = 'X';
const COMPUTER_MARKER = 'O';

export default function Game() {
  const [strength, setStrength] = useState('Unbeatable');

  const handleRadio = (e) => {
    setStrength(e.target.value);
  }

  return (
    <div className="game">
      <div className="header"><h2>Tic-Tac-Toe</h2></div>
      <div className="game-board">
        <Board strength={strength} />
      </div>
      <div className="computer-strength-container">
        <ComputerStrength 
          className="strength" 
          value={'Computer Strength'} 
          strength={strength} 
          onRadioChange={(e) => handleRadio(e)}
        />
      </div>
    </div>
  )
}

function Board({strength}) {
  console.log(strength);

  const [squares, setSquares] = useState(Array(9).fill(null));

  let status;

  let winner = checkWinner(squares);

  if (winner) {
    status = `Winner: ${winner}`;
    showResetButton();
  } else if (squares.some(s => s === null)) {
    status = `Click a square ...`;
  } else {
    status = `Draw`;
    showResetButton();
  }

  const handleClick = i => {
    if (squares[i] || winner) return;

    const nextSquares = [...squares];
    nextSquares[i] = PLAYER_MARKER;
    winner = checkWinner(nextSquares);

    if (!winner) {
      nextSquares[getComputerMove(nextSquares, strength)] = COMPUTER_MARKER;
    }

    setSquares(nextSquares);
  }

  const handleReset = () => {
    setSquares(Array(9).fill(null));
    showResetButton(false);
  }

  return (
    <>
      <div className="board">
        <div className="status">{status}</div>
        <div className="board-row">
          <Square value={squares[0]} onSquareClick={() => handleClick(0)} />
          <Square value={squares[1]} onSquareClick={() => handleClick(1)} />
          <Square value={squares[2]} onSquareClick={() => handleClick(2)} />
        </div>
        <div className="board-row">
          <Square value={squares[3]} onSquareClick={() => handleClick(3)} />
          <Square value={squares[4]} onSquareClick={() => handleClick(4)} />
          <Square value={squares[5]} onSquareClick={() => handleClick(5)} />
        </div>
        <div className="board-row">
          <Square value={squares[6]} onSquareClick={() => handleClick(6)} />
          <Square value={squares[7]} onSquareClick={() => handleClick(7)} />
          <Square value={squares[8]} onSquareClick={() => handleClick(8)} />
        </div>
      </div>
      <div className="reset-container">
        <Button className="reset" value="New Game" onButtonClick={() => handleReset()} />
      </div>
    </>
  );

}

function Square({value, onSquareClick}) {
  return (
    <button 
      className="square"
      onClick={onSquareClick}
    >
      {value}
    </button>
  );
}

function Button({value, onButtonClick}) {
  return (
    <button className="reset" onClick={onButtonClick}>{value}</button>
  )
}

function ComputerStrength({value, strength, onRadioChange}) {
  return ( 
    <div className="computer-strength">
      <p>{value}</p>
      <label>
        <input type="radio" 
          name="strength"
          value="Unbeatable"
          id="unbeatable"
          checked={strength === "Unbeatable"}
          onChange={onRadioChange}
        />
        Unbeatable
      </label>

      <label>
        <input type="radio" 
          name="strength"
          value="Decent"
          id="decent"
          checked={strength === "Decent"}
          onChange={onRadioChange}
        />
        Decent
      </label>

      <label>
        <input type="radio" 
          name="strength"
          value="Pitiful"
          id="pitiful"
          checked={strength === "Pitiful"}
          onChange={onRadioChange}
        />
        Pitiful
      </label>
    </div>
  )
}

function showResetButton(show = true) {
  let resetStyle = document.querySelector('.reset-container').style
  // resetStyle.display = show ? 'block' : 'none';
  resetStyle.visibility = show ? 'visible' : 'hidden';
}

function checkWinner(squares) {
  for (let line of WINNING_LINES) { 
    const lineVals = getLineVals(squares, line);
    if (lineVals[0] && new Set(lineVals).size === 1) return lineVals[0];
  }

  return null;
}

function getLineVals(squares, line) {
  return line.map(squareIndex => squares[squareIndex]);
}

// We want any value from 0 through 8, but JS treats 0 as falsy.
// So the || construct doesn't work if the index we want is 0. 
// Adding 1 to the return value and then subtracting it back
// out again works around this.
function getComputerMove(squares, strength) {
  console.log(strength);
  if (strength === 'Pitiful') return randomMove(squares);

  let move =  
    takeComputerWin(squares) + 1 ||
    blockPlayerWin(squares) + 1 ||
    takeCenter(squares) + 1 || 
    (strength === 'Unbeatable' ? cornerPlay(squares) + 1 : undefined) ||
    randomMove(squares) + 1;

  return move - 1;
}

function takeComputerWin(squares) {
  console.log('takeComputerWin');
  for (let line of WINNING_LINES) {
    const lineVals = getLineVals(squares, line);
    const computerSquares = lineVals.filter(sq => sq === COMPUTER_MARKER);
    if (computerSquares.length === 2 && lineVals.includes(null)) {
      return line[lineVals.findIndex(val => val === null)];
    }
  }

  return undefined;
}

function blockPlayerWin(squares) {
  console.log('blockPlayerWin');
  for (let line of WINNING_LINES) {
    const lineVals = getLineVals(squares, line);
    const playerSquares = lineVals.filter(sq => sq === PLAYER_MARKER);
    if (playerSquares.length === 2 && lineVals.includes(null)) {
      return line[lineVals.findIndex(val => val === null)];
    }
  }

  return undefined;
}

function takeCenter(squares) {
  console.log('takeCenter');
  return !squares[4] ? 4 : undefined;
}

function cornerPlay(squares) {
  console.log('cornerPlay');
  if (
    [squares[0], squares[8]].every(sq => sq === PLAYER_MARKER) ||
    [squares[2], squares[6]].every(sq => sq === PLAYER_MARKER)
  ) {
    console.log('opposite corners');
    return [1, 3, 5, 7].find(i => squares[i] === null);
  } else if (squares[5] === PLAYER_MARKER && squares[7] === PLAYER_MARKER) {
    console.log('two squares');
    return [2, 6, 8].find(i => squares[i] === null);
  } else {
    console.log('else');
    return [0, 2, 6, 8].find(i => squares[i] === null);
  }

  return undefined;
}

function randomMove(squares) {
  console.log('randomMove');
  const openSquares = [];
  squares.forEach((e, i) => {
    if (e === null) openSquares.push(i);
  });
  // console.log(openSquares);
  return openSquares[Math.floor(Math.random() * openSquares.length)];
}
